import React, { useState } from "react";
import { connect } from "react-redux";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down.svg";
import { toast } from "react-toastify";
import { useDetectClickOutside } from "react-detect-click-outside";
import { convertToDt } from "utils/convertData";
import DropIconUp from "assets/images/icons/dropdown-arrow-up.svg";
import DropIconDown from "assets/images/icons/dropdown-arrow-down.svg";
import { updateDateRange } from "redux/actions";

import "./AtlasCalendarSelect.scss";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const MONTHS_ROWS = [
  MONTHS.slice(0, 4),
  MONTHS.slice(4, 8),
  MONTHS.slice(8, 12),
];

const AtlasCalendarSelect = ({ style, dateRange, updateDateRange, topic }) => {
  const [showMenu, setShowMenu] = useState(false);

  const dt_from = new Date(topic.startDate);
  const dt_to = new Date(topic.endtDate);

  const validRange = {
    from: {
      year: dt_from.getFullYear(),
      month: dt_from.getMonth(),
    },
    to: {
      year: dt_to.getFullYear(),
      month: dt_to.getMonth(),
    },
  };

  return (
    <div style={{ ...style }} className="atlas-calendar-select-container">
      <div
        className="atlas-calendar-select-control"
        onClick={() => setShowMenu(true)}
      >
        <CalendarIcon width="20" height="19" fill="#78D2F1" stroke="#78D2F1" />
        <span>
          {`${MONTHS[dateRange.from.month]} ${dateRange.from.year}`} -{" "}
          {`${MONTHS[dateRange.to.month]} ${dateRange.to.year}`}
        </span>
        <ArrowIcon
          height="18"
          width="18"
          fill="#8D9CA6"
          stroke="#8D9CA6"
          strokeWidth="1"
          style={{ position: "relative", top: "3px" }}
        />
      </div>

      {showMenu && (
        <CustomCalendar
          from={dateRange.from}
          to={dateRange.to}
          validRange={validRange}
          onClose={() => setShowMenu(false)}
          onChange={(x) => {
            updateDateRange({ from: x.dateFrom, to: x.dateTo });
          }}
        />
      )}
    </div>
  );
};

interface CustomDropDownProps {
  list: {
    id: string;
    title: number;
  }[];
  defaultValue: number;
  onChange: (year: number) => void;
}

function CustomDropDown({ list, defaultValue, onChange }: CustomDropDownProps) {
  const [headerTitle, setHeaderTitle] = useState(defaultValue);
  const [isListOpen, setIsListOpen] = useState(false);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setIsListOpen(false);
    },
  });

  const toggleList = () => {
    setIsListOpen((prev) => !prev);
  };

  const selectItem = (item) => {
    setIsListOpen(false);
    setHeaderTitle(item.title);
    if (onChange) onChange(item.title);
  };

  return (
    <div className="dd-wrapper" ref={ref}>
      <div className="dd-header" onClick={toggleList}>
        <div className="dd-header-title">{headerTitle}</div>
        {isListOpen ? (
          <img src={DropIconUp} alt="drop-indicator" />
        ) : (
          <img src={DropIconDown} alt="drop-indicator" />
        )}
      </div>
      <div
        role="list"
        className="dd-list"
        style={{ display: isListOpen ? "block" : "none" }}
      >
        {list.map((item) => (
          <div
            className="dd-list-item"
            key={item.id}
            onClick={() => selectItem(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
}

interface CustomCalendarProps {
  from: AtlasMach.IDate;
  to: AtlasMach.IDate;
  validRange: { from: AtlasMach.IDate; to: AtlasMach.IDate };
  // Callback when the user made an action that should close the menu
  onClose: () => void;
  onChange: (value: any) => void;
}

function CustomCalendar({
  from,
  to,
  validRange,
  onClose,
  onChange,
}: CustomCalendarProps) {
  const [fromDate, setFromDate] = useState(from);
  const [toDate, setToDate] = useState(to);
  const [showFromYear, setShowFromYear] = useState(from.year);
  const [showToYear, setShowToYear] = useState(to.year);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      onClose();
    },
  });

  const handleOkay = () => {
    const dt = convertToDt(fromDate, toDate);
    if (dt.dt_from > dt.dt_to) {
      toast("Invalid date selection", {
        position: "top-left",
        type: toast.TYPE.ERROR,
      });
      return;
    }
    if (onChange) {
      onChange({
        dateFrom: fromDate,
        dateTo: toDate,
      });
    }
    onClose();
  };

  function withinValidRange({ year, month }) {
    if (year < validRange.from.year || year > validRange.to.year) return false;

    if (validRange.from.year === validRange.to.year)
      return month >= validRange.from.month && month <= validRange.to.month;

    if (year === validRange.from.year) return month >= validRange.from.month;

    if (year === validRange.to.year) return month <= validRange.to.month;

    return true;
  }

  return (
    <div className="atlas-calendar-select-menu" ref={ref}>
      <div className="atlas-calendar-select-menu-part">
        <div className="atlas-calendar-select-menu-part-header">
          <label>FROM</label>
          <CustomDropDown
            list={[
              { id: "2019", title: 2019 },
              { id: "2020", title: 2020 },
              { id: "2021", title: 2021 },
              { id: "2022", title: 2022 },
              { id: "2023", title: 2023 },
              { id: "2024", title: 2024 },
              { id: "2025", title: 2025 },
            ]}
            defaultValue={fromDate.year}
            onChange={(year) => setShowFromYear(year)}
          />
        </div>
        <div className="part-body">
          <div className="cmc-table">
            {MONTHS_ROWS.map((row, idx) => (
              <div className="cmc-row" key={`rowmf-${idx}`}>
                {row.map((month, mdx) => {
                  const isValidMonth = withinValidRange({
                    year: showFromYear,
                    month: idx * 4 + mdx,
                  });
                  return (
                    <div
                      className={`cmc-item ${
                        showFromYear === fromDate.year &&
                        fromDate.month === idx * 4 + mdx
                          ? "active"
                          : ""
                      } ${isValidMonth ? "valid" : "invalid"}`}
                      key={`monthf-${month}`}
                      onClick={() => {
                        if (!isValidMonth) return;

                        setFromDate({
                          year: showFromYear,
                          month: idx * 4 + mdx,
                        });
                      }}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="atlas-calendar-select-menu-part">
        <div className="atlas-calendar-select-menu-part-header">
          <label>TO</label>
          <CustomDropDown
            list={[
              { id: "2019", title: 2019 },
              { id: "2020", title: 2020 },
              { id: "2021", title: 2021 },
              { id: "2022", title: 2022 },
              { id: "2023", title: 2023 },
              { id: "2024", title: 2024 },
              { id: "2025", title: 2025 },
            ]}
            defaultValue={to.year}
            onChange={(year) => setShowToYear(year)}
          />
        </div>
        <div className="part-body">
          <div className="cmc-table">
            {MONTHS_ROWS.map((row, idx) => (
              <div className="cmc-row" key={`rowmt-${idx}`}>
                {row.map((month, mdx) => {
                  const isValidMonth = withinValidRange({
                    year: showToYear,
                    month: idx * 4 + mdx,
                  });
                  return (
                    <div
                      className={`cmc-item ${
                        showToYear === toDate.year &&
                        toDate.month === idx * 4 + mdx
                          ? "active"
                          : ""
                      } ${isValidMonth ? "valid" : "invalid"}`}
                      key={`montht-${month}`}
                      onClick={() => {
                        if (!isValidMonth) return;

                        setToDate({ year: showToYear, month: idx * 4 + mdx });
                      }}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      <button className="atlas-calendar-select-menu-ok" onClick={handleOkay}>
        OK
      </button>
    </div>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState, ownProps) => {
  return {
    dateRange: state.ui.dateRange,
    topic: state.ui.topic,
  };
};

const mapDispatchToProps = {
  updateDateRange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtlasCalendarSelect);
