import {
  HIDE_MENU,
  SWITCH_VIEW_MODE,
  UPDATE_PERCENT,
  UPDATE_TOPIC,
  UPDATE_DATE_RANGE,
  UPDATE_FILTERS,
  UPDATE_PROFILE_COUNT,
  UPDATE_SIDEOPTION,
  PUSH_CHARTPOSITION,
  SET_CHARTREF,
  SET_ACCOUNT,
  SET_ISLOADING,
  SET_STARACCOUNTS,
} from "../actionTypes";

const now = new Date();

const initialState: AtlasMach.UIState = {
  topic: undefined,
  dateRange: { from: { year: 2019, month: 0 }, to: { year: 2025, month: 0 } },
  profileCount: 0,
  mode: "graph",
  isLoading: false,
  account: undefined,
  sideOption: {
    dateFrom: {
      year: now.getFullYear(),
      month: now.getMonth(),
    },
    dateTo: {
      year: now.getFullYear(),
      month: now.getMonth(),
    },
    topic: "",
    subtopics: [],
    source: "",
    audiences: [],
    starred: false,
    engagements: [],
  },
  filters: undefined,
  chartRef: undefined,
  chartPositionStack: {},
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TOPIC: {
      const { topic } = action.payload;
      return {
        ...state,
        topic: topic,
      };
    }
    case UPDATE_DATE_RANGE: {
      const { dateRange } = action.payload;
      return {
        ...state,
        dateRange: dateRange,
      };
    }
    case UPDATE_PROFILE_COUNT: {
      const { count } = action.payload;
      return {
        ...state,
        profileCount: count,
      };
    }
    case UPDATE_PERCENT: {
      const { percent } = action.payload;
      return {
        ...state,
        percent: percent,
      };
    }
    case SET_ACCOUNT: {
      const { account } = action.payload;
      return {
        ...state,
        account: account,
      };
    }
    case SWITCH_VIEW_MODE: {
      const { mode } = action.payload;
      return {
        ...state,
        mode: mode,
      };
    }
    case SET_ISLOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        isLoading: loading,
      };
    }
    case UPDATE_SIDEOPTION: {
      const { option } = action.payload;
      return {
        ...state,
        sideOption: option,
      };
    }
    case UPDATE_FILTERS: {
      const { filters } = action.payload;
      return {
        ...state,
        filters: filters,
      };
    }
    case SET_CHARTREF: {
      const { ref } = action.payload;
      return {
        ...state,
        chartRef: ref,
      };
    }
    case PUSH_CHARTPOSITION: {
      const { key, chart } = action.payload;
      let stack = { ...state.chartPositionStack };
      stack[key] = chart;
      return {
        ...state,
        chartPositionStack: stack,
      };
    }
    default:
      return state;
  }
}
